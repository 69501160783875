import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/home/runner/work/armeria/armeria/site/src/layouts/release-notes.tsx";
export const _frontmatter = {};
const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};
const ThankYou = makeShortcode("ThankYou");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <p {...{
      "className": "date"
    }}>{`22nd September 2020`}</p>


    <h2 {...{
      "id": "-new-features",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#-new-features",
        "aria-label": " new features permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`🌟 New features`}</h2>
    <ul>
      <li parentName="ul">{`You can now inject your own `}<a parentName="li" {...{
          "href": "type://MeterIdPrefixFunction:https://javadoc.io/doc/com.linecorp.armeria/armeria-javadoc/latest/com/linecorp/armeria/common/metric/MeterIdPrefixFunction.html"
        }}>{`type://MeterIdPrefixFunction`}</a>{` when using Armeria Spring Boot integration. `}<a parentName="li" {...{
          "href": "https://github.com/line/armeria/issues/3034"
        }}>{`#3034`}</a>
        <pre parentName="li"><code parentName="pre" {...{
            "className": "language-java"
          }}>{`@Bean
public MeterIdPrefixFunction myMeterIdPrefixFunction() {
    return MeterIdPrefixFunction.of("custom.armeria.server");
}
`}</code></pre>
      </li>
      <li parentName="ul">{`You can now disable TLS verification for certain hosts only. `}<a parentName="li" {...{
          "href": "https://github.com/line/armeria/issues/2722"
        }}>{`#2722`}</a>{` `}<a parentName="li" {...{
          "href": "https://github.com/line/armeria/issues/3057"
        }}>{`#3057`}</a>
        <pre parentName="li"><code parentName="pre" {...{
            "className": "language-java"
          }}>{`ClientFactoryBuilder builder = ...
// You should never use this in production but only for a testing purpose.
builder.tlsNoVerifyHosts("trustin.com", "trustus.com");
`}</code></pre>
      </li>
      <li parentName="ul">{`You can now use a string to specify the `}<inlineCode parentName="li">{`Logger`}</inlineCode>{` for `}<a parentName="li" {...{
          "href": "type://LoggingService:https://javadoc.io/doc/com.linecorp.armeria/armeria-javadoc/latest/com/linecorp/armeria/server/logging/LoggingService.html"
        }}>{`type://LoggingService`}</a>{` and `}<a parentName="li" {...{
          "href": "type://LoggingClient:https://javadoc.io/doc/com.linecorp.armeria/armeria-javadoc/latest/com/linecorp/armeria/client/logging/LoggingClient.html"
        }}>{`type://LoggingClient`}</a>{`. `}<a parentName="li" {...{
          "href": "https://github.com/line/armeria/issues/3035"
        }}>{`#3035`}</a>
        <pre parentName="li"><code parentName="pre" {...{
            "className": "language-java"
          }}>{`ServerBuilder sb = ...
sb.decorator(LoggingService.builder()
                           .logger("com.foo")
                           .build());
`}</code></pre>
      </li>
    </ul>
    <h2 {...{
      "id": "-improvements",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#-improvements",
        "aria-label": " improvements permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`📈 Improvements`}</h2>
    <ul>
      <li parentName="ul"><a parentName="li" {...{
          "href": "type://RequestLog#serviceName()"
        }}>{`type://RequestLog#serviceName()`}</a>{` is now used as a span name if you use the default `}<inlineCode parentName="li">{`HttpRequestParser`}</inlineCode>{`
and `}<inlineCode parentName="li">{`HttpResponseParser`}</inlineCode>{` with `}<a parentName="li" {...{
          "href": "type://BraveClient:https://javadoc.io/doc/com.linecorp.armeria/armeria-javadoc/latest/com/linecorp/armeria/client/brave/BraveClient.html"
        }}>{`type://BraveClient`}</a>{` and `}<a parentName="li" {...{
          "href": "type://BraveService:https://javadoc.io/doc/com.linecorp.armeria/armeria-javadoc/latest/com/linecorp/armeria/server/brave/BraveService.html"
        }}>{`type://BraveService`}</a>{` for recognizing
a span easily. `}<a parentName="li" {...{
          "href": "https://github.com/line/armeria/issues/3023"
        }}>{`#3023`}</a></li>
      <li parentName="ul">{`You can now easily find out whether SPI service interfaces are applied from the logs. `}<a parentName="li" {...{
          "href": "https://github.com/line/armeria/issues/3039"
        }}>{`#3039`}</a></li>
      <li parentName="ul">{`You can now get the log of the exception which is raised while converting a `}<a parentName="li" {...{
          "href": "type://RequestLog:https://javadoc.io/doc/com.linecorp.armeria/armeria-javadoc/latest/com/linecorp/armeria/common/logging/RequestLog.html"
        }}>{`type://RequestLog`}</a>{` into a
`}<inlineCode parentName="li">{`String`}</inlineCode>{`. `}<a parentName="li" {...{
          "href": "https://github.com/line/armeria/issues/3043"
        }}>{`#3043`}</a></li>
      <li parentName="ul">{`The stack trace of `}<a parentName="li" {...{
          "href": "type://HttpStatusException:https://javadoc.io/doc/com.linecorp.armeria/armeria-javadoc/latest/com/linecorp/armeria/server/HttpStatusException.html"
        }}>{`type://HttpStatusException`}</a>{` and `}<a parentName="li" {...{
          "href": "type://HttpResponseException:https://javadoc.io/doc/com.linecorp.armeria/armeria-javadoc/latest/com/linecorp/armeria/server/HttpResponseException.html"
        }}>{`type://HttpResponseException`}</a>{` are not logged anymore by
`}<a parentName="li" {...{
          "href": "type://LoggingService:https://javadoc.io/doc/com.linecorp.armeria/armeria-javadoc/latest/com/linecorp/armeria/server/logging/LoggingService.html"
        }}>{`type://LoggingService`}</a>{` because a user already knows where they are come from. `}<a parentName="li" {...{
          "href": "https://github.com/line/armeria/issues/3053"
        }}>{`#3053`}</a>{`, `}<a parentName="li" {...{
          "href": "https://github.com/line/armeria/issues/3054"
        }}>{`#3054`}</a></li>
    </ul>
    <h2 {...{
      "id": "️-bug-fixes",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#%EF%B8%8F-bug-fixes",
        "aria-label": "️ bug fixes permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`🛠️ Bug fixes`}</h2>
    <ul>
      <li parentName="ul"><inlineCode parentName="li">{`TimeoutScheduler`}</inlineCode>{` is now initialized exactly once. `}<a parentName="li" {...{
          "href": "https://github.com/line/armeria/issues/3066"
        }}>{`#3066`}</a></li>
      <li parentName="ul">{`Reactor subscriber context is now properly propagated. `}<a parentName="li" {...{
          "href": "https://github.com/line/armeria/issues/3058"
        }}>{`#3058`}</a></li>
      <li parentName="ul">{`Armeria Spring Boot integration collects metrics correctly if `}<a parentName="li" {...{
          "href": "type://ArmeriaSettings#enableMetrics"
        }}>{`type://ArmeriaSettings#enableMetrics`}</a>{`
is `}<inlineCode parentName="li">{`true`}</inlineCode>{`. `}<a parentName="li" {...{
          "href": "https://github.com/line/armeria/issues/3048"
        }}>{`#3048`}</a></li>
      <li parentName="ul">{`You now see the proper exception from WebFlux `}<inlineCode parentName="li">{`WebClient`}</inlineCode>{` when `}<a parentName="li" {...{
          "href": "type://CircuitBreakerClient:https://javadoc.io/doc/com.linecorp.armeria/armeria-javadoc/latest/com/linecorp/armeria/client/circuitbreaker/CircuitBreakerClient.html"
        }}>{`type://CircuitBreakerClient`}</a>{` is applied. `}<a parentName="li" {...{
          "href": "https://github.com/line/armeria/issues/3064"
        }}>{`#3064`}</a></li>
      <li parentName="ul">{`Armeria client now rejects a `}<inlineCode parentName="li">{`PUSH_PROMISE`}</inlineCode>{` frame correctly because it doesn't support it yet. `}<a parentName="li" {...{
          "href": "https://github.com/line/armeria/issues/3045"
        }}>{`#3045`}</a>
        <ul parentName="li">
          <li parentName="ul"><inlineCode parentName="li">{`SETTINGS_ENABLE_PUSH`}</inlineCode>{` is now disabled.`}</li>
        </ul>
      </li>
      <li parentName="ul">{`Armeria gRPC server does not raise a `}<inlineCode parentName="li">{`NullPointerException`}</inlineCode>{` anymore when a gRPC server interceptor closes
the `}<inlineCode parentName="li">{`ServerCall`}</inlineCode>{`. `}<a parentName="li" {...{
          "href": "https://github.com/line/armeria/issues/3022"
        }}>{`#3022`}</a></li>
    </ul>
    <h2 {...{
      "id": "-dependencies",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#-dependencies",
        "aria-label": " dependencies permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`⛓ Dependencies`}</h2>
    <ul>
      <li parentName="ul">{`Brave 5.12.4 → 5.12.6`}</li>
      <li parentName="ul">{`Dropwizard 2.0.12 → 2.0.13`}</li>
      <li parentName="ul">{`gRPC 1.31.1 → 1.32.1`}</li>
      <li parentName="ul">{`grpc-kotlin-stub 0.1.5 → 0.2.0`}</li>
      <li parentName="ul">{`Micrometer 1.5.4 → 1.5.5`}</li>
      <li parentName="ul">{`Netty 4.1.51.FINAL → 4.1.52.FINAL`}
        <ul parentName="li">
          <li parentName="ul">{`netty-tcnative-boringssl-static 2.0.31.Final → 2.0.34.Final`}</li>
        </ul>
      </li>
      <li parentName="ul">{`Tomcat 9.0.37 → 9.0.38`}</li>
      <li parentName="ul">{`org.jetbrains.kotlinx 1.3.8 → 1.3.9`}</li>
      <li parentName="ul">{`Spring 5.2.8.RELEASE → 5.2.9.RELEASE`}</li>
      <li parentName="ul">{`Spring Boot 2.3.3.RELEASE → 2.3.4.RELEASE`}</li>
      <li parentName="ul">{`Examples:`}
        <ul parentName="li">
          <li parentName="ul">{`Dagger 2.28.3 → 2.29.1`}</li>
          <li parentName="ul">{`monix-reactive_2.13 3.2.2+43-36c1ada8 → 3.2.2+45-5c6c8b9e`}</li>
          <li parentName="ul">{`org.jetbrains.kotlin 1.3.72 → 1.4.10`}</li>
        </ul>
      </li>
    </ul>
    <h2 {...{
      "id": "-thank-you",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#-thank-you",
        "aria-label": " thank you permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`🙇 Thank you`}</h2>
    <ThankYou usernames={['aanrii', 'anuraaga', 'codefromthecrypt', 'eonezhang', 'ghkim3221', 'ikhoon', 'kojilin', 'mauhiz', 'minwoox', 'okue', 'rickyrattlesnake', 'trustin', 'tumile', 'wooyeong']} mdxType="ThankYou" />

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      